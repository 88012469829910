import React, { useState } from "react";
import {
  Box,
  Menu,
  MenuItem,
  Typography,
  ButtonBase,
  ListItemText,
  Divider,
  Button,
} from "@mui/material";
import ArrowDown from "src/assets/icons/arrowDown";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useTheme } from "@mui/material/styles";

import { useRouter } from "next/router";

import useProjects from "src/hooks/useProjects";
import useKYCStatus from "src/hooks/useKYCStatus";
import useModal from "src/hooks/useModal";
import KycDialog from "src/components/dialogs/KYCDialog";
import apiConfig from "src/configs/api";
import { getRoute, getRouteWithParams } from "src/routes";
import { isEmptyObject, isKycVerified } from "src/configs/helper";
import { useAuth } from "src/hooks/useAuth";
import { Region } from "src/types/regions";

const ProjectSelector: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const {user} = useAuth();
  const { projects, selectedProjectId, setSelectedProjectId } = useProjects();
  const { kycStatus, trialStatus } = useKYCStatus();
  const { handleOpenModal, handleCloseModal, modalActive } = useModal();
  const router = useRouter();
  const theme = useTheme();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOrderProject = () => {
    if (
      !kycStatus ||
      !trialStatus ||
      !isKycVerified(kycStatus, trialStatus.account_type)
    ) {
      handleOpenModal("kycDialog");
      handleClose();
    } else {
      const route = getRouteWithParams("orderProject");
      router.push(route);
      handleClose();
    }
  };

  const showProjects = () => {
    if (selectedProjectId) {
      if (projects?.[selectedProjectId]?.name) {
        return projects[selectedProjectId].name;
      } else {
        return selectedProjectId;
      }
    } else {
      return "Projects";
    }
  };

  const handleClickProject = (projectId: string) => {
    if (!projects || isEmptyObject(projects) || !Object.keys(projects).length) {
      return;
    }
    setSelectedProjectId((prevSelectedProjectId) => {
      if (prevSelectedProjectId === projectId) {
        handleClose();
        return prevSelectedProjectId;
      } else {
        const hostingId = projects[projectId].hostingId;

        let projectRegion:Region | null =  null;
        if(router.query[apiConfig.currentRegion]) {
          for(const region of Object.values(projects[projectId].regions)) {
            if(router.query[apiConfig.currentRegion] && region.identifier === router.query[apiConfig.currentRegion] ) {
              projectRegion = region;
              break;
            }
          }
        }
       
        const firstRegionKey = Object.keys(projects[projectId].regions)[0];
        const isFirstProjectRegionAccessible = projects[projectId].regions[firstRegionKey].is_accessible
        const firstIdentifier = projects[projectId].regions[firstRegionKey].identifier;

        if(projectRegion?.is_accessible) {
          router.push({
            pathname: getRoute('dashboard'),
            query: {
              ...router.query,
              [apiConfig.projectId]: projectId,
              [apiConfig.hostingId]: hostingId,
            },
          });
          handleClose();
          return projectId;
        } 
        else if(projectRegion && !projectRegion.is_accessible) {
          router.push({
            pathname: getRoute('getStarted'),
            query: {
              ...router.query,
              [apiConfig.projectId]: projectId,
              [apiConfig.hostingId]: hostingId,
            },
          });
          handleClose();
          return projectId;
        }
        else if (!isFirstProjectRegionAccessible) {
          router.push({
            pathname: getRoute('getStarted'),
            query: {
              ...router.query,
              [apiConfig.currentRegion] : firstIdentifier,
              [apiConfig.projectId]: projectId,
              [apiConfig.hostingId]: hostingId,
            },
          });
          handleClose();
          return projectId;
        }

        router.push({
          pathname: getRoute('dashboard'),
          query: {
            ...router.query,
            [apiConfig.currentRegion] : firstIdentifier,
            [apiConfig.projectId]: projectId,
            [apiConfig.hostingId]: hostingId,
          },
        });
        handleClose();
        return projectId;
      }
    });
  };

  const renderProjectsList = () => {
    if (projects && Object.keys(projects).length) {
      return Object.entries(projects).map(([projectId, project]) => (
        <MenuItem
          key={projectId}
          onClick={() => {
            handleClickProject(projectId);
          }}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            color: selectedProjectId === projectId ? "primary.main" : "inherit",
          }}
        >
          <ListItemText
            primary={project.name ? project.name : projectId}
            primaryTypographyProps={{
              style: {
                fontWeight: 500,
                color:
                  selectedProjectId === projectId
                    ? theme.palette.primary.main
                    : "inherit",
              },
            }}
          />
        </MenuItem>
      ));
    } else {
      return (
        <MenuItem disabled>
          <Typography variant="body1">No Projects</Typography>
        </MenuItem>
      );
    }
  };

  return (
    <>
      <Box>
        <ButtonBase
          onClick={handleClick}
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            width: "100%",
          }}
        >
          <Typography
            variant="body1"
            marginRight="10px"
            marginLeft="10px"
            fontWeight={600}
            fontSize={"14px !important"}
            fontFamily={"Poppins, sans-serif"}
            letterSpacing={"0 !important"}
            color={theme.palette.grey[600]}
          >
            <>{showProjects()}</>
          </Typography>
          <ArrowDown />
        </ButtonBase>

        <Menu
          id="project-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          MenuListProps={{ style: { padding: 0, maxHeight: "30vh" } }}
        >
          {renderProjectsList()}
          <Divider />
            {user?.policies?.some(policy =>
            policy?.commonServices?.some(service => service?.name === 'Order New Project' && service?.access)
            ) && (
            <Button
              variant="text"
              color="primary"
              startIcon={<AddCircleOutlineIcon />}
              fullWidth
              onClick={handleOrderProject}
              size="small"
              sx={{ my: 2 }}
            >
              Order Project
            </Button>
            )}
        </Menu>
      </Box>
      {modalActive.isModalOpen && modalActive.modalName === "kycDialog" && (
        <KycDialog
          open={
            modalActive.isModalOpen && modalActive.modalName === "kycDialog"
          }
          onCloseModal={handleCloseModal}
        />
      )}
    </>
  );
};

export default ProjectSelector;
