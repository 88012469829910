import nProgress from "nprogress";
import Cookie from "js-cookie";
import { toast } from "react-toastify";
// import toast from "react-hot-toast";
import cookie from "js-cookie";
import Router from "next/router";

import {
  KUBERNETES_BASE_URL,
  accountTypeWithoutKyc,
  billingServices,
  defaultAmount,
  defaultCurrencyCode,
  defaultCurrencyPrefix,
  infiniteFetchSize,
} from "src/configs/constants";
import showToast from "src/utils/notifications";
import { UserDataType, UserKYCStatusType } from "src/context/types";
import { AxiosError, isAxiosError } from "axios";
import { XMLParser } from "fast-xml-parser";
import { HTTP_RESPONSE_CODE } from "src/configs/constants";
import { SERVER_ERROR_MESSAGE } from "src/language/en/message";
import { useSelector } from "react-redux";
import { RootState } from "src/redux-store/store";
import { sendKubernetesRequest } from "src/store/store";
import { ReactElement, ReactNode, SetStateAction } from "react";
import React from "react";
import { fetchToken } from "src/helpers/helper";
import { ErrorType } from "src/types/error";
import appConfig from "src/configs/app";
import { getRoute } from "src/routes";
import apiConfig from "src/configs/api";
import { errorLogInSentry } from "src/store/sentry";
import { moduleNames } from "src/utils/moduleName";
import { toastMessage } from "src/language/en/message";
import { CarouselImages, CarouselImagesList } from "src/types/instances/images";
import { format, isValid, parseISO } from "date-fns";
import { toZonedTime } from "date-fns-tz";

export const getLocalStoreItem = (key: string) => {
  if (!key) return null;
  const value = localStorage.getItem(key);
  if (value) {
    return JSON.parse(value);
  }
  return null;
};

export const getLocalStoreItemRaw = (key: string) => {
  if (!key) return null;
  const value = localStorage.getItem(key);
  if (value) {
    return value;
  }
  return null;
};

export const removeEmptyQuery = (obj: {
  [key: string]: string | undefined | null;
}) => {
  const newObj: { [key: string]: string } = {};
  Object.keys(obj).forEach((key: string) => {
    if (obj[key] !== undefined && obj[key] !== null) {
      newObj[key] = obj[key] as string;
    }
  });

  return newObj;
};

export const calculateTimeDifference = (createdTime: string) => {
  const currentTime = new Date();
  const ticketCreatedTime = new Date(createdTime);

  const differenceInSeconds = Math.floor(
    (currentTime.getTime() - ticketCreatedTime.getTime()) / 1000,
  );

  const secondsInMinute = 60;
  const secondsInHour = 3600;
  const secondsInDay = 86400;
  const secondsInMonth = 2592000;

  const minutes = Math.floor(differenceInSeconds / secondsInMinute);
  const hours = Math.floor(differenceInSeconds / secondsInHour);
  const days = Math.floor(differenceInSeconds / secondsInDay);
  const months = Math.floor(differenceInSeconds / secondsInMonth);

  if (differenceInSeconds < secondsInMinute) {
    return "Few seconds ago";
  } else if (differenceInSeconds < secondsInHour) {
    return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
  } else if (differenceInSeconds < secondsInDay) {
    return `${hours} h ago`;
  } else if (differenceInSeconds < secondsInMonth) {
    return `${days} day${days > 1 ? "s" : ""} ago`;
  } else {
    return `${months} month${months > 1 ? "s" : ""} ago`;
  }
};

export const calculateTimeRange = (hours: number) => {
  const currentDate = new Date();
  const targetDate = new Date(currentDate.getTime() - hours * 60 * 60 * 1000);
  return targetDate.toISOString();
};
export const createBreadcrumbItems = (
  path: string,
): { title: string; href: string }[] => {
  // First, remove the query string from the full path if it exists
  const basePath = path.split("?")[0];

  // Then split the cleaned path into segments
  const paths = basePath.split("/").filter((path) => path);

  const breadcrumbItems = paths.map((path, index) => {
    // Generate URL for each breadcrumb item
    const href = "/" + paths.slice(0, index + 1).join("/");

    // Process the title to make each word capitalized and replace dashes with spaces
    const title = path.includes("-")
      ? path
        .split("-")
        .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
        .join(" ")
      : path.charAt(0).toUpperCase() + path.slice(1); // Capitalize first letter

    return { title, href };
  });

  return breadcrumbItems;
};

export const getDateTime = (date: string | Date, separator = "") => {
  if (!date) {
    return null;
  }
  const convertedDate = new Date(date);

  return (
    convertedDate.toLocaleDateString("en-GB") +
    " " +
    separator +
    " " +
    convertedDate.toLocaleTimeString("en-GB")
  );
};

export const ucfirst = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const ucwords = (string: string, separator = " ") => {
  if (typeof string !== "string") {
    return string;
  }
  const newString = string.split(separator);
  newString.forEach((word, index) => {
    newString[index] = ucfirst(word);
  });

  return newString.join(" ");
};

export function handleLoading(loading: boolean): void {
  if (loading) {
    nProgress.start();
  } else {
    nProgress.done();
  }
}
export function errorSetter(error: object, setError: any): void {
  Object.entries(error).forEach(([apiErrorField, apiErrorMessage]) => {
    if (apiErrorField && apiErrorMessage) {
      if (
        Array.isArray(apiErrorMessage) ||
        typeof apiErrorMessage === "string"
      ) {
        setError(apiErrorField, {
          type: "manual",
          message: Array.isArray(apiErrorMessage)
            ? apiErrorMessage.join(", ")
            : typeof apiErrorMessage === "string"
              ? apiErrorMessage
              : "Invalid data provided",
        });
      } else if (typeof apiErrorMessage === "object") {
        Object.entries(apiErrorMessage).forEach(
          ([nestedField, nestedMessage]) => {
            if (nestedField && nestedMessage) {
              setError(`${apiErrorField}.${nestedField}`, {
                type: "manual",
                message: Array.isArray(nestedMessage)
                  ? nestedMessage.join(", ")
                  : typeof nestedMessage === "string"
                    ? nestedMessage
                    : "Invalid data provided",
              });
            }
          },
        );
      }
    }
  });
}

export const errorHandler = (
  error: ErrorType,
  setError?: (error: object) => void,
  customMessage = "Invalid data provided",
) => {
  if (!error.response || (error.response && !error.response.data)) {
    if (error.message) {
      if (error.message.toLowerCase() === "unauthorized") {
        return;
      }
      toast.error(error.message);
    } else {
      toast.error(SERVER_ERROR_MESSAGE);
    }
  } else if (error.response && error.response.message) {
    toast.error(error.response.message);
  } else {
    if (
      error.response.data &&
      (error.response.data.errors || error.response.data.message)
    ) {
      if (error.response.data.errors && setError) {
        setError(error.response.data.errors);
      } else if (
        error.response.data.errors &&
        error.response.data.errors.length
      ) {
        error.response.data.errors.forEach((error: { message: string }) => {
          if (error.message) {
            toast.error(error.message);
          }
        });
      } else {
        toast.error(error.response.data.message);
      }
    } else {
      toast.error(customMessage);
    }
  }

  return;
};

export const debounce = (func: () => void, delay: number) => {
  const debounceTimeout = setTimeout(func, delay);
  return debounceTimeout;
};

export const copyToClipboard = async (text: string): Promise<boolean> => {
  try {
    await navigator.clipboard.writeText(text);
    toast.success("Copied to clipboard");

    return true;
  } catch (error) {
    errorLogInSentry(
      error,
      {},
      "clipboard.writeText",
      "copyToClipboard",
      "src/configs/helper.ts",
    );
    return false;
  }
};

export const k8sCopyToClipboard = async (
  text: string,
  key: string,
): Promise<Boolean> => {
  let transformedKey: string = key
    .replace(/_/g, " ")
    .replace(/(?:^|\s)\S/g, (match: string) => match.toUpperCase());

  if ("clipboard" in navigator) {
    try {
      await navigator.clipboard.writeText(text);
      showToast("info", `Successfully copied ${transformedKey}`);
      return true;
    } catch (error) {
      errorLogInSentry(
        error,
        {},
        "clipboard.writeText",
        "k8sCopyToClipboard",
        "src/configs/helper.ts",
      );
      return false;
    }
  } else {
    document.execCommand("copy", true, text);
    showToast("info", `Successfully copied ${transformedKey}`);
    return false;
  }
};

// ***************************** Download yaml File **********************************
export async function downloadYaml(yamlContent: string, filename: string) {
  const element = document.createElement("a");
  const file = new Blob([yamlContent], { type: "application/yaml" });
  element.href = URL.createObjectURL(file);
  element.download = `${filename}.yaml`;
  document.body.appendChild(element); // Required for this to work in Firefox
  element.click();
}

interface ResponseWrap extends Response {
  parsedData: unknown;
  response: unknown;
}

export const fetchWrap = async (
  url: string,
  params: RequestInit,
  noContentType?: boolean,
) => {
  try {
    const token = await fetchToken();
    const response = (await fetch(url, {
      ...params,
      headers: noContentType
        ? {
          Authorization: `Bearer ${token}`,
          ...params?.headers,
        }
        : {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          ...params?.headers,
        },
    })) as ResponseWrap;
    if (response?.ok) {
      const responseData = await response?.json();
      return { response, parsedData: responseData?.data };
    } else {
      if (
        response &&
        response.status === 401 &&
        response.statusText.toLowerCase() === "unauthorized"
      ) {
        localStorage.removeItem(apiConfig.storageTokenKeyName);
        await Router.push(getRoute("logout"));
        throw new Error("Unauthorized");
      }
      const errorData = await response.json();
      return errorData;
    }
  } catch (e) {
    const error = e as { message: string };

    throw new Error(error.message);
  }
};

export const toFirstLetterUpper = (str: string): string => {
  if (str.length === 0) {
    return "";
  }

  if (str.length === 1) {
    return str.toUpperCase();
  }

  return str
    .toLowerCase()
    .split(" ")
    .map((word) => {
      return word[0].toUpperCase() + word.substring(1);
    })
    .join(" ");
};

export const getFullName = (user: UserDataType | null) => {
  if (!user) return "John Doe";
  const { first_name, last_name } = user;
  return `${first_name || "John"} ${last_name || ""}`.trim();
};

export const onDownload = (
  itemName: string,
  itemValue: string,
  fileType: string,
  fileExtension?: string,
  itemType?: string,
): void => {
  if (!itemName || !itemValue) return;

  const file = new Blob([itemValue], { type: fileType });
  const element = document.createElement("a");

  element.href = URL.createObjectURL(file);
  element.download = `${itemName}${fileExtension}`;

  document.body.appendChild(element);

  setTimeout(() => {
    element.click();
    document.body.removeChild(element);
    URL.revokeObjectURL(element.href);
  }, 100);

  showToast(
    "info",
    `Downloading the ${itemType ? itemType + ":" : "file,"} ${itemName || ""}`,
  );
};

export const handleCopyClick = async (
  itemValue: string,
  itemName: string,
): Promise<void> => {
  if (itemValue) {
    await navigator.clipboard.writeText(itemValue);
    showToast("success", `Copied ${itemName ? itemName : "the item"}`);
  }
};

export const deleteCookie = (name: string) => {
  if (!name) {
    return;
  }
  document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
};

export const generateApiUrl = (
  url: string,
  appendLimit: boolean = false,
): string => {
  const queryParams: URLSearchParams = new URLSearchParams(
    window && window.location && window.location.search
      ? window.location.search
      : "",
  );

  const urlParams: string = `region=${queryParams.get(apiConfig.currentRegion)}&${apiConfig.projectId}=${queryParams.get(apiConfig.projectId)}`;

  let finalUrl: string = url.includes("?")
    ? url + "&" + urlParams
    : url + "?" + urlParams;

  if (appendLimit) {
    finalUrl += `&limit=${infiniteFetchSize}`;
  }

  return finalUrl;
};
export const apiErrorMessage = (error: AxiosError): string => {
  if (
    error &&
    error.response &&
    error.response.data &&
    typeof error.response.data === "object" &&
    "message" in error.response.data
  ) {
    if (typeof error.response.data.message === "string") {
      return String(error.response.data.message);
    } else if (
      typeof error.response.data.message === "object" &&
      error.response.data.message !== null &&
      "message" in error.response.data.message &&
      error.response.data.message.message &&
      typeof error.response.data.message.message === "string"
    ) {
      return String(error.response.data.message["message"]);
    }
  } else if (error && error.message) {
    if (typeof error.message === "string") {
      return error.message;
    }
  }
  return "Unable to process the request. Please try again later";
};

export const getExternalNetworks = (
  modalDataObjectArray: { [key: string]: string | number | boolean }[],
  setExternalSubnets: React.Dispatch<
    React.SetStateAction<Array<{ [key: string]: string }>>
  >,
): void => {
  setExternalSubnets([]);
  if (modalDataObjectArray && modalDataObjectArray.length > 0) {
    modalDataObjectArray.forEach(
      (network: { [key: string]: string | number | boolean | string[] }) => {
        if (network["router_external"] === true) {
          setExternalSubnets(
            (
              externalSubnets: Array<{ [key: string]: string }>,
            ): Array<{ [key: string]: string }> => [
                ...externalSubnets,
                { value: String(network.id), text: String(network.name) },
              ],
          );
        }
      },
    );
    setExternalSubnets((prevState) => [
      { value: "none", text: `Select ${getModuleName("vpc", 3)}` },
      ...prevState,
    ]);
  }
};

/**
 * Sorts an array of objects by a specified property, with optional descending order.
 * Supports sorting by strings, numbers, and dates.
 * If the property values are not of a comparable type, falls back to default comparison.
 *
 * @param arr The array of objects to be sorted.
 * @param property The property key by which to sort the objects.
 * @param descending Optional. Specifies whether to sort in descending order. Default is false (ascending).
 * @returns A new array containing the sorted objects.
 */
export const sortByProperty = <T>(
  arr: T[],
  property: keyof T,
  descending: boolean,
): T[] => {
  const sortedArray = arr.slice().sort((a, b) => {
    const valueA = a[property];
    const valueB = b[property];

    const isDateA = !isNaN(new Date(valueA as string).getTime());
    const isDateB = !isNaN(new Date(valueB as string).getTime());

    if (isDateA && isDateB) {
      // Compare dates
      const dateA = new Date(valueA as string);
      const dateB = new Date(valueB as string);
      return descending
        ? dateB.getTime() - dateA.getTime()
        : dateA.getTime() - dateB.getTime();
    } else if (typeof valueA === "string" && typeof valueB === "string") {
      // Compare strings
      return descending
        ? valueB.localeCompare(valueA)
        : valueA.localeCompare(valueB);
    } else if (typeof valueA === "number" && typeof valueB === "number") {
      // Compare numbers
      return descending ? valueB - valueA : valueA - valueB;
    } else {
      // Fallback to default comparison (may not work well for all types)
      return descending ? +valueB - +valueA : +valueA - +valueB;
    }
  });

  return sortedArray;
};

/**
 * Converts a camelCase string to title case.
 * For example, "camelCase" becomes "Camel Case".
 *
 * @param str The camelCase string to convert.
 * @returns The string converted to title case.
 */
export const camelCaseToTitleCase = (str: string) => {
  return (
    str
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      // Capitalize the first letter
      .replace(/^./, function (s) {
        return s.toUpperCase();
      })
  );
};

/**
 * Handles backend errors by extracting error message and error code from AxiosError or Error object.
 * If the error is an AxiosError and contains a response with data and status, attempts to parse XML error data.
 * If parsing is successful, returns the error message and status code from the parsed data.
 * If parsing fails or the error is not an AxiosError, returns the error message and a default status code (500).
 *
 * @param error The AxiosError or Error object representing the backend error.
 * @returns An object containing the extracted error message and error code.
 */
export const backendErrorHandler = (
  error: AxiosError | Error,
): { errorMessage: string; errorCode: number } => {
  if (isAxiosError(error)) {
    if (error.response && error.response.data && error.response.status) {
      try {
        const xmlErrorData = error.response.data;
        const options = {
          ignoreAttributes: false,
        };
        const parser = new XMLParser(options);
        const parsedData = parser.parse(xmlErrorData);

        return {
          errorMessage: parsedData.Error.Message
            ? parsedData.Error.Message
            : parsedData.Error.Code
              ? parsedData.Error.Code
              : SERVER_ERROR_MESSAGE,
          errorCode: error.response.status,
        };
      } catch (error) {
        errorLogInSentry(
          error,
          {},
          "error",
          "backendErrorHandler",
          "src/configs/helper.ts",
        );
        return {
          errorMessage: SERVER_ERROR_MESSAGE,
          errorCode: HTTP_RESPONSE_CODE.SERVER_ERROR,
        };
      }
    }
  }

  return {
    errorMessage: (error as Error).message,
    errorCode: HTTP_RESPONSE_CODE.SERVER_ERROR,
  };
};

export const ReactToastifyErrorHandler = (error: AxiosError | Error) => {
  if (isAxiosError(error)) {
    if (error.response && error.response.data && error.response.data.error) {
      if (error.response.data.message instanceof String) {
        return error.response.data.message;
      } else if (Array.isArray(error.response.data.message)) {
        const allMessages = error.response.data.message.join("\n");
        return allMessages;
      }
      return error.response.data.message;
    } else {
      return SERVER_ERROR_MESSAGE;
    }
  } else {
    return (error as Error).message;
  }
};

export const useResourceComponent = () => {
  const { setComponentFunction } = useSelector((state: RootState) => {
    return state.setComponentFunction;
  });
  return setComponentFunction;
};
export const useIdComponent = () => {
  const { setIdFunction } = useSelector((state: RootState) => {
    return state.setComponentFunction;
  });
  return setIdFunction;
};
export const getTimeAgo = (timestamp: string): string => {
  const currentTime = new Date();
  const previousTime = new Date(timestamp);
  const timeDifference = currentTime.getTime() - previousTime.getTime();

  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  if (days > 1) {
    return `${days} days ago`;
  } else if (hours > 1) {
    return `${hours} hours ago`;
  } else if (minutes > 1) {
    return `${minutes} minutes ago`;
  } else {
    return `${seconds} seconds ago`;
  }
};

export const downloadKubernetesYaml = async (
  apiEndpoint: string,
  fileName: string,
): Promise<void> => {
  try {
    const headers = {
      "Content-Type": "application/x-yaml",
      download: "true",
    };
    const response = await sendKubernetesRequest(
      apiEndpoint,
      "GET",
      null,
      headers,
    );
    await downloadYaml(response, fileName);
  } catch (e: any) {
    errorLogInSentry(
      e,
      {},
      "GET",
      "downloadKubernetesYaml",
      "src/configs/helper.ts",
    );
    showToast(
      "error",
      "Error: " + e?.response?.data?.message || "Request failed",
    );
  }
};

export const deleteK8sEntity = async (
  apiEndpoint: string,
  setComponent?: (value: ReactNode) => void,
  component?: ReactNode,
) => {
  try {
    await sendKubernetesRequest(apiEndpoint, "DELETE");
    setComponent && setComponent(component);
  } catch (e: any) {
    errorLogInSentry(
      e,
      {},
      "DELETE",
      "deleteK8sEntity",
      "src/configs/helper.ts",
    );
    showToast("error", e?.response?.data?.message || "Request failed");
    throw new Error(e);
  }
};

export const editK8sYaml = async (
  fetchYamlEndpoint: string,
  editReqEndpoint: string,
  data: { [key: string]: string },
  heading: string,
  setComponent: (value: ReactNode) => void,
  component: ReactNode,
  EditYaml: ReactElement,
) => {
  try {
    const headers = {
      "Content-Type": "application/x-yaml",
      download: "true",
    };
    const yamlData: string = await sendKubernetesRequest(
      fetchYamlEndpoint,
      "GET",
      null,
      headers,
    );

    const editData: {
      heading: string;
      component: ReactNode;
      saveChange: ({
        jsonData,
      }: {
        jsonData: Record<string, unknown>;
      }) => Promise<void>;
    } = {
      heading: heading,
      component: component,
      saveChange: async ({
        jsonData,
      }: {
        jsonData: Record<string, unknown>;
      }) => {
        const data: {
          updates: Record<string, unknown>;
        } = {
          updates: jsonData,
        };
        try {
          await sendKubernetesRequest(editReqEndpoint, "PUT", data);
          setComponent(component);
        } catch (e: any) {
          errorLogInSentry(
            e,
            {},
            "PUT",
            "sendKubernetesRequest",
            "src/configs/helper.ts",
          );
          showToast(
            "error",
            "Error: " + e?.response?.data?.message || "Request failed",
          );
        }
      },
    };

    const combinedData = { ...data, ...editData, yamlData };
    setComponent(React.cloneElement(EditYaml, { data: combinedData }));
  } catch (e: any) {
    errorLogInSentry(e, {}, "GET", "editK8sYaml", "src/configs/helper.ts");
    showToast(
      "error",
      "Error: " + e?.response?.data?.message || "Request failed",
    );
  }
};

export const parseData = (value: string): string => {
  try {
    const parsedData = JSON.parse(value);
    if (typeof parsedData === "object") {
      return JSON.stringify(parsedData, null, 2);
    } else {
      return value;
    }
  } catch (error) {
    errorLogInSentry(error, {}, "", "parseData", "src/configs/helper.ts");
    return value;
  }
};

export const handleAnnotationCopyClick = async (
  key: string,
  value: string,
  index: number,
  setCommonState: Function,
): Promise<void> => {
  if (value === "" || value === "<Empty>") {
    setCommonState((prevCommonState: any) => ({
      ...prevCommonState,
      copyButtonText: {
        ...prevCommonState.copyButtonText,
        [index]: {
          text: "Error copying",
          variant: "contained",
          color: "error",
        },
      },
    }));
  } else {
    if ("clipboard" in navigator) {
      await navigator.clipboard.writeText(value);
      showToast("info", `Successfully copied ${key}`);
    } else {
      document.execCommand("copy", true, value);
      showToast("info", `Successfully copied ${key}`);
    }
    setCommonState((prevCommonState: any) => ({
      ...prevCommonState,
      copyButtonText: {
        ...prevCommonState.copyButtonText,
        [index]: { text: "Copied!", variant: "contained", color: "success" },
      },
    }));
  }
};

export const handleK8sAnnotationBlur = async (
  index: number,
  setCommonState: Function,
): Promise<void> => {
  setCommonState((prevCommonState: any) => ({
    ...prevCommonState,
    copyButtonText: {
      ...prevCommonState.copyButtonText,
      [index]: { text: "Copy", variant: "outlined", color: "primary" },
    },
  }));
};

export function convertUnixTimestampToDate(timestamp: string) {
  // Create a new Date object from the Unix timestamp (in milliseconds)
  const date = new Date(+timestamp * 1000); // Convert seconds to milliseconds

  // Format the date components
  const day = date.getUTCDate().toString().padStart(2, "0");
  const month = (date.getUTCMonth() + 1).toString().padStart(2, "0"); // Month is 0-indexed
  const year = date.getUTCFullYear();
  const hours = date.getUTCHours().toString().padStart(2, "0");
  const minutes = date.getUTCMinutes().toString().padStart(2, "0");
  const seconds = date.getUTCSeconds().toString().padStart(2, "0");

  // Return the formatted string
  return `${day}/${month}/${year} : ${hours}:${minutes}:${seconds}`;
}

export const getFormatedEmail = (email: string): string => {
  if (!email.includes("+")) {
    return email;
  }
  const emailParts = email.split("+");
  if (emailParts.length < 2) {
    return email;
  }
  const newMail = emailParts[0] + "@" + emailParts[1].split("@")[1];
  return newMail;
};

export function isEmptyObject(data: object) {
  return (
    data === undefined || !data || (data && Object.keys(data).length === 0)
  );
}

export const getMemoryString = (
  memoryValue: number,
  memoryType: string,
): string => {
  const memoryUnits = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  let index = memoryUnits.indexOf(memoryType);
  if (index === -1) {
    throw new Error("Invalid memory type provided");
  }

  while (memoryValue >= 1024 && index < memoryUnits.length - 1) {
    memoryValue /= 1024;
    index++;
  }

  return (
    (memoryValue !== 0 ? memoryValue.toFixed(2) : memoryValue) +
    " " +
    memoryUnits[index]
  );
};
export const convertQueryToDisplayText = (
  dateRange: string | undefined,
  fromDate: string | undefined,
  toDate: string | undefined,
): string => {
  if (dateRange) {
    switch (dateRange) {
      case "last24hours":
        return "Last 24 Hours";
      case "last7days":
        return "Last 7 Days";
      case "last14days":
        return "Last 14 Days";
      case "last30days":
        return "Last 30 Days";
      default:
        return "Select Range";
    }
  } else if (fromDate && toDate) {
    const formattedFromDate = formatDate(fromDate);
    const formattedToDate = formatDate(toDate);
    return `${formattedFromDate} to ${formattedToDate}`;
  } else {
    return "Select Range";
  }
};

export const formatDate = (dateString: string): string => {
  if (!dateString) {
    return "---";
  }

  const date = new Date(dateString);

  if (isNaN(date.getTime())) {
    return "---";
  }

  const dd = String(date.getDate()).padStart(2, "0");
  const mm = String(date.getMonth() + 1).padStart(2, "0");
  const yyyy = date.getFullYear();

  return `${dd}/${mm}/${yyyy}`;
};

export const filterK8sLBData = (
  values: {
    [key: string]: string | boolean | number | { [key: string]: string }[];
  },
  sources?: { [key: string]: string }[],
): {
  loadbalancer: { [key: string]: string };
  listenerDetail: {
    [key: string]: string | { [key: string]: string | string[] };
  };
  poolDetail: { [key: string]: string };
  poolMember: {
    name: string;
    ipAddress: string;
    protocol_port: number;
    weight: number;
  }[];
  monitorDetail: { [key: string]: string };
} => {
  let filteredData = {
    loadbalancer: {},
    listenerDetail: {},
    poolDetail: {},
    poolMember: Array(),
    monitorDetail: {},
  };
  try {
    if (values.loadBalancerName) {
      filteredData.loadbalancer = {
        name: values.loadBalancerName,
        description: values.loadBalancerDescription,
      };
    }

    if (values.createListener && values.createListener === "Yes") {
      filteredData.listenerDetail = {
        name: values.listenerName,
        description: values.listenerDescription,
        protocol: values.listenerProtocol,
        protocol_port: values.listenerPort,
        timeout_client_data: values.clientDataTimeout,
        timeout_tcp_inspect: values.tcpInspectTimeout,
        timeout_member_connect: values.memberConnectTimeout,
        timeout_member_data: values.memberDataTimeout,
        connection_limit: values.connectionLimit,
        admin_state_up: values.listenerAdminStateUp === "Yes" ? true : false,
      };

      if (values.listenerProtocol === "HTTP") {
        filteredData.listenerDetail = {
          ...filteredData.listenerDetail,
          insert_headers: {
            "X-Forwarded-For": values.headerFor
              ? values.headerFor.toString()
              : "",
            "X-Forwarded-Port": values.headerPort
              ? values.headerPort.toString()
              : "",
            "X-Forwarded-Proto": values.headerProto
              ? values.headerProto.toString()
              : "",
          },
        };
      }

      if (sources) {
        (filteredData.listenerDetail as any).allowed_cidrs = [];
        sources.map((source: { [key: string]: string }) => {
          (filteredData.listenerDetail as any).allowed_cidrs.push(source.value);
        });
      }
    }

    if (values.createPool && values.createPool === "Yes") {
      filteredData.poolDetail = {
        lb_algorithm: values.poolAlgorithm,
        description: values.poolDescription,
        admin_state_up: values.poolAdminStateUp === "Yes" ? true : false,
        ...(values.poolSessionPersistence !== "None" && {
          session_persistence: {
            ...(values.poolSessionPersistence === "APP_COOKIE" && {
              cookie_name: values.poolCookieName,
            }),
            type: values.poolSessionPersistence,
          },
        }),
        name: values.poolName,
        tls_enabled: values.poolTlsEnabled === "Yes" ? true : false,
        tls_ciphers: values.poolTlsCipherString,
      };
    }

    if (Array.isArray(values.allocatedMembers) && values.allocatedMembers) {
      values.allocatedMembers.map((value) => {
        filteredData.poolMember.push({
          name: value.name,
          ipAddress: value.ip,
          protocol_port: value.port,
          weight: value.weight,
        });
      });
    }

    if (values.createHealthMonitor && values.createHealthMonitor === "Yes") {
      filteredData.monitorDetail = {
        name: values.healthMonitorName,
        type: values.healthMonitorType,
        max_retries: values.healthMonitorMaxRetries,
        max_retries_down: values.healthMonitorMaxRetriesDown,
        timeout: values.healthMonitorTimeout,
        admin_state_up:
          values.healthMonitorAdminStateUp === "Yes" ? true : false,
        delay: values.healthMonitorDelay,
        ...(values.healthMonitorType.toString().includes("HTTP") && {
          http_method: values.healthMonitorHttpMethod,
          expected_codes: values.healthMonitorExpectedCodes,
          url_path: values.healthMonitorUrlPath,
        }),
      };
    }

    return filteredData;
  } catch (e: any) {
    errorLogInSentry(
      e,
      {},
      "FILTER",
      "filterK8sLBData",
      "src/configs/helper.ts",
    );
    return filteredData;
  }
};

export const checkProjectQuota = async (projectDetails: {
  [key: string]: string | number | boolean;
}) => {
  try {
    const data = {
      quantity: projectDetails.quantity,
      volume: projectDetails.volume.toString(),
      flavorId: projectDetails.flavorName.toString().split(" | ")[0].trim(),
      ...(projectDetails.flavorType.toString() !== "GPU"
        ? { cpu: true }
        : { gpu: true }),
    };
    await sendKubernetesRequest("compute/check-project-quota", "post", data);
    return { status: true };
  } catch (e: any) {
    errorLogInSentry(
      e,
      {},
      "GET",
      "checkProjectQuota",
      "src/configs/helper.ts",
    );
    return { status: false, message: e?.response?.data?.message };
  }
};
export const convertArrayToAccept = (input: string[]): string => {
  const accept = input
    .map((ext) => {
      if (ext === "") return "";
      return "." + ext;
    })
    .join(", ");
  return accept;
};

export const getParsedInputTypes = (): string[] => {
  let inputTypes = appConfig.allowedFileTypes as string;
  inputTypes = inputTypes.replace(/\s/g, "");
  const parsedInputTypes = JSON.parse(inputTypes);

  return parsedInputTypes;
};

export const pushToLogin = () => {
  if (appConfig.loginPagePath && appConfig.loginPagePath.length) {
    return (window.location.href = getRoute("login"));
  }
  Router.push(getRoute("login"));
};
export const formatDateWithTime = (
  isoDate: string,
  showSeconds: boolean = false,
): string => {
  if (!isoDate) {
    return "---";
  }

  const date = new Date(isoDate);

  if (isNaN(date.getTime())) {
    return "---";
  }

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  const dateTimeStringWithoutSeconds = `${day}-${month}-${year} ${hours}:${minutes}`;
  const dateTimeStringWithSeconds = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;

  // Return formatted date in "dd-mm-yyyy hh:mm" or "dd-mm-yyyy hh:mm:ss" format

  return showSeconds ? dateTimeStringWithSeconds : dateTimeStringWithoutSeconds;
};
export const getFormattedDate = (customDate?: Date) => {
  const date = new Date(customDate || new Date());
  const dd = String(date.getDate()).padStart(2, "0");
  const mm = String(date.getMonth() + 1).padStart(2, "0");
  const yyyy = date.getFullYear();

  return `${yyyy}-${mm}-${dd}`;
};

export const checkValidCharacters = (
  inputString: string,
  allowedChars: string[],
): boolean => {
  let isValidName = true;
  allowedChars.forEach((char) => {
    if (inputString.includes(char)) {
      isValidName = false;
      return;
    }
  });
  return isValidName;
};

export const secondsToHours = (seconds: number): number => {
  return seconds / 3600;
};

export const bytesToGBs = (bytes: number): number => {
  return bytes / (1024 * 1024 * 1024);
};

export const formatTimestamp = (timestamp: string | undefined): string => {
  try {
    if (!timestamp) return "NA";
    const date = parseISO(timestamp);
    if (!isValid(date)) return "NA";
    const utcDate = toZonedTime(date, "UTC");
    return format(utcDate, "yyyy-MM-dd HH:mm:ss");
  } catch (error) {
    return "NA";
  }
};

export const getCeiling = (num: number): string => {
  try {
    const ceiling = Math.round(num * 10000) / 10000;
    if (ceiling === 0) return defaultAmount;
    return ceiling.toFixed(2);
  } catch (error) {
    return defaultAmount;
  }
};

export const normalizeDecimalString = (amount: string): string => {
  try {
    if (!amount) return "0.00";

    amount = amount.trim();
    if (amount.startsWith(".")) amount = "0" + amount;
    if (!/^\d+(\.\d+)?$/.test(amount)) return "0.00";

    return amount;
  } catch (error) {
    return "0.00";
  }
};

export const getCurrencyPrefix = (code: string): string => {
  if (!code) return "$";
  return code.toLowerCase() === "inr" ? "₹" : "$";
};

export const displayAmount = (
  code: string = defaultCurrencyCode,
  amount: number | string = 0,
): string => {
  try {
    let numericAmount: number = 0;
    if (typeof amount === "string") {
      numericAmount = Number(amount);
      if (isNaN(numericAmount)) {
        numericAmount = 0;
      }
    } else {
      numericAmount = amount;
    }
    const currencyPrefix = getCurrencyPrefix(code);
    const ceilAmount = getCeiling(numericAmount);
    return `${currencyPrefix}${ceilAmount}`;
  } catch (error) {
    return `${defaultCurrencyPrefix}${defaultAmount}`;
  }
};

export const formatDuration = (seconds: number): string => {
  let hours = Math.floor(seconds / 3600);
  let minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  if (remainingSeconds >= 30) minutes += 1;

  if (minutes === 60) {
    hours += 1;
    minutes = 0;
  }

  const formattedDuration = `${String(hours).padStart(2, "0")}.${String(minutes).padStart(2, "0")}`;
  return formattedDuration;
};

export const formatResource = (resource: string): string => {
  if (!resource) return "";

  if (billingServices[resource]) return billingServices[resource];

  return resource;
};

export const getUrlWithQueryParams = (url: string) => {
  const queryParams = new URLSearchParams(
    window && window.location && window.location.search
      ? window.location.search
      : "",
  );
  const params = {
    [apiConfig.currentRegion]: queryParams.get(apiConfig.currentRegion),
    [apiConfig.timezone]: queryParams.get(apiConfig.timezone),
    [apiConfig.hostingId]: queryParams.get(apiConfig.hostingId),
    [apiConfig.projectId]: queryParams.get(apiConfig.projectId),
  };

  // Filter out undefined, null or empty parameters and create query string
  const queryString = Object.entries(params)
    .filter(
      ([key, value]) => value !== null && value !== undefined && value !== "",
    )
    .map(([key, value]) => `${key}=${encodeURIComponent(value as string)}`) // 'value as string' to assure TypeScript of the type
    .join("&");

  // Construct the full route with query parameters if any
  const existingQuery =
    url && url.includes("?") && url.split("?")[1] ? url.split("?")[1] : "";
  return queryString
    ? `${url.split("?")[0]}?${existingQuery}&${queryString}`
    : url;
};
export const getModuleName = (
  module: string,
  versionNumber: number = 1,
): string => {
  if (module.trim().length > 0 && Number.isInteger(versionNumber)) {
    if (
      moduleNames[module] &&
      moduleNames[module]["version" + versionNumber] !== undefined &&
      moduleNames[module]["version" + versionNumber] !== null
    ) {
      return moduleNames[module]["version" + versionNumber];
    }
  }

  return toFirstLetterUpper(module);
};
export const getToastMessage = (
  action: string,
  status: string,
  isMultiple: boolean = false,
): string => {
  if (!toastMessage || !toastMessage[action] || !toastMessage[action][status]) {
    return "";
  }

  if (
    action.toLowerCase() === "delete" &&
    status.toLowerCase() === "success" &&
    isMultiple
  ) {
    // Handle toast message for multiple delete
    return toastMessage[action].successMultiple
      ? toastMessage[action].successMultiple
      : "Successfully deleted the selected";
  }

  return toastMessage[action][status];
};

export const checkValidationError = (error: object) => {
  if (
    error &&
    error instanceof AxiosError &&
    error.response &&
    error.response.data &&
    error.response.data.message &&
    typeof error.response.data.message === "object"
  ) {
    return true;
  }
  return false;
};

export const checkValidUUID = (input: string): boolean => {
  const regexExp =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

  return regexExp.test(input);
};

export const convertParamsToString = (params: string[]): string => {
  const arr = params.map((param) => `"${param}"`);
  const result = `[${arr.join(", ")}]`;
  return result;
};

export const findImageById = (
  images: CarouselImagesList,
  id: string,
): { selectedImage: CarouselImages; index: number } => {
  const selectedImageObject = {
    selectedImage: { imageFullName: "", os: "" },
    index: -1,
  };

  for (const key in images) {
    const carouselImages = images[key].carouselImages;
    const image = carouselImages.find(
      (image: CarouselImages) => image.id === id,
    );
    if (image) {
      selectedImageObject.selectedImage = image;
      selectedImageObject.index = carouselImages.indexOf(image);
    }
  }

  return selectedImageObject;
};

export const arrayToString = (
  arr: string[],
  separator?: string,
  lastSeparator?: string,
): string => {
  if (arr.length === 0) return "";
  if (arr.length === 1) return arr[0];

  const allButLast = arr.slice(0, -1);
  const lastElement = arr[arr.length - 1];

  return (
    allButLast.join(separator || ", ") + (lastSeparator || " & ") + lastElement
  );
};
export const formatStringCase = (
  str: string,
  delimiter: string,
  separator: string,
): string => {
  if (!str) return "";
  return str
    .split(delimiter)
    .map((word) => {
      return toFirstLetterUpper(word);
    })
    .join(separator);
};

export const createURIComponent = (str: string): string => {
  try {
    if (str.includes("+")) {
      str = str.replace(/\+/g, "%2B");
    }
    return encodeURIComponent(str);
  } catch (error) {
    return str;
  }
};

export const customDecodeURIComponent = (str: string): string => {
  if (str.includes("%2B")) {
    str = str.replace(/%2B/g, "+");
  } else if (str.includes("+")) {
    str = str.replace(/\+/g, " ");
  }
  try {
    const decodedString = decodeURIComponent(str);
    return decodedString;
  } catch (error) {
    return str;
  }
};

export const simplifyQuotaValue = (value: number, divide_by?: number) => {
  if (!value) {
    return 0;
  }
  if (value === -1) {
    return "(No Limit)";
  } else if (divide_by && divide_by > 0) {
    return value / divide_by;
  } else {
    return value;
  }
};

export const getClusterInfo = async (
  setLoading: (value: SetStateAction<boolean>) => void,
  setKubernetesStatus: React.Dispatch<
    React.SetStateAction<{ clusterStatus: string }>
  >,
  setDeploymentInterval: React.Dispatch<
    React.SetStateAction<NodeJS.Timeout | undefined>
  >,
) => {
  try {
    setLoading(true);
    const response = await sendKubernetesRequest(
      `cluster-overview/cluster-status`,
      "get",
    );
    if (response.ok === true) {
      setKubernetesStatus(response);
      setLoading(false);
    } else {
      setLoading(false);
      setKubernetesStatus((kubernetesStatus) => ({
        ...kubernetesStatus,
        clusterStatus: response.clusterStatus,
      }));
      await new Promise((resolve, reject) => {
        let deploymentInterval = setInterval(async () => {
          try {
            const response = await sendKubernetesRequest(
              `cluster-overview/cluster-status`,
              "get",
            );
            if (response.ok === true) {
              setKubernetesStatus(response);
              clearInterval(deploymentInterval);
              resolve("Success");
            } else {
              setKubernetesStatus((kubernetesStatus) => ({
                ...kubernetesStatus,
                clusterStatus: response.clusterStatus,
              }));
              if (response.clusterStatus === "Never Created")
                clearInterval(deploymentInterval);
            }
          } catch (error) {
            clearInterval(deploymentInterval);
            reject("Unable to Retrieve Status of Deployment");
          }
        }, 60 * 1000);
        setDeploymentInterval(deploymentInterval);
      });
    }
  } catch (e: any) {
    setLoading(false);
    showToast(
      "error",
      e?.response?.data?.message || "Unable to retrive cluster data!",
    );
  }
};

export const convertToMCPUs = (value: string) => {
  if (value === "") {
    return value;
  }
  if (typeof value === "string") {
    if (value.endsWith("k")) {
      // '1k' -> 1000 CPUs -> 1000000 mCPUs
      return parseInt(value.replace("k", "")) * 1000 * 1000;
    } else if (value.endsWith("M")) {
      // '1M' -> 1,000,000 CPUs -> 1,000,000,000 mCPUs
      return parseInt(value.replace("M", "")) * 1000 * 1000 * 1000;
    } else if (value.endsWith("G")) {
      // '1G' -> 1,000,000,000 CPUs -> 1,000,000,000,000 mCPUs
      return parseInt(value.replace("G", "")) * 1000 * 1000 * 1000 * 1000;
    } else if (value.endsWith("m")) {
      // '100m' -> 100 mCPUs
      return parseInt(value.replace("m", ""));
    }
  }
  // Handle whole number CPUs and decimal values
  return parseInt(value) * 1000;
};

export const convertToMiB = (value: string) => {
  if (value === "") {
    return value;
  }
  if (typeof value === "string") {
    if (value.endsWith("Mi")) {
      // '1Mi' -> 1 MiB
      return parseInt(value.replace("Mi", ""));
    } else if (value.endsWith("Gi")) {
      // '1Gi' -> 1 GiB = 1024 MiB
      return parseInt(value.replace("Gi", "")) * 1024;
    } else if (value.endsWith("Ti")) {
      // '1Ti' -> 1 TiB = 1024 * 1024 MiB
      return parseInt(value.replace("Ti", "")) * 1024 * 1024;
    } else if (value.endsWith("Pi")) {
      // '1Pi' -> 1 PiB = 1024 * 1024 * 1024 MiB
      return parseInt(value.replace("Pi", "")) * 1024 * 1024 * 1024;
    } else if (value.endsWith("Ei")) {
      // '1Ei' -> 1 EiB = 1024 * 1024 * 1024 * 1024 MiB
      return parseInt(value.replace("Ei", "")) * 1024 * 1024 * 1024 * 1024;
    }
  }
  // Assume any whole number is in bytes
  return parseInt(value) / (1024 * 1024);
};

export const convertCronToTime = (cronSchedule: string) => {
  if (cronSchedule) {
    // Split the cron schedule into its components
    const [minute, hour, dayOfMonth, month, dayOfWeek]: string[] =
      cronSchedule.split(" ");

    if (!minute || !hour || !dayOfMonth || !month || !dayOfWeek) {
      return "Invalid cron schedule";
    }

    const months: string[] = [
      "",
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const daysOfWeek: string[] = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    // Convert hour from 24-hour format to 12-hour format
    const hourInt: number = parseInt(hour, 10);
    const minuteInt: number = parseInt(minute, 10);
    const dayOfMonthInt: number = parseInt(dayOfMonth, 10);

    if (
      isNaN(hourInt) ||
      isNaN(minuteInt) ||
      hourInt < 0 ||
      hourInt > 23 ||
      minuteInt < 0 ||
      minuteInt > 59 ||
      (!isNaN(dayOfMonthInt) && (dayOfMonthInt < 1 || dayOfMonthInt > 31))
    ) {
      return "Invalid cron schedule";
    }

    const period: "PM" | "AM" = hourInt >= 12 ? "PM" : "AM";
    const hour12: number = hourInt % 12 || 12; // Convert 0 to 12 for midnight

    // Pad the minute with leading zero if needed
    const minuteStr: string = minuteInt.toString().padStart(2, "0");

    let monthName: string = "";
    if (month !== "*") {
      const monthIndex: number = parseInt(month, 10);
      if (isNaN(monthIndex) || monthIndex < 1 || monthIndex > 12) {
        return "Invalid cron schedule";
      }
      monthName = months[monthIndex];
    }

    let dayOfWeekName: string = "";
    if (dayOfWeek !== "*") {
      const dayOfWeekIndex: number = parseInt(dayOfWeek, 10);
      if (isNaN(dayOfWeekIndex) || dayOfWeekIndex < 0 || dayOfWeekIndex > 6) {
        return "Invalid cron schedule";
      }
      dayOfWeekName = daysOfWeek[dayOfWeekIndex];
    }
    // Construct the detailed human-readable time string
    let timeStr: string = `At ${hour12}:${minuteStr} ${period}`;
    if (dayOfMonth !== "*") {
      timeStr += `, on day ${dayOfMonth} of the month`;
    }
    if (dayOfWeek !== "*") {
      timeStr += `, and on ${dayOfWeekName}`;
    }
    if (month !== "*") {
      timeStr += `, only in ${monthName}`;
    }
    return timeStr;
  } else {
    return "Invalid cron schedule, 'dddd";
  }
};

export const getModules = (): { [key: string]: string } => {
  return {
    all: "All modules",
    instance: getModuleName("instance"),
    interface: getModuleName("interfaces"),
    volume: getModuleName("volume"),
    snapshot: getModuleName("snapshot"),
    image: getModuleName("image"),
    backup: getModuleName("backup"),
    vpn: getModuleName("vpn"),
    vpc: getModuleName("vpc"),
    router: getModuleName("routers", 3),
    "floating-ip": getModuleName("floatingIp"),
    "load-balancer": getModuleName("loadBalancer"),
    "security-group": getModuleName("securityGroup"),
    "security-group-rule": getModuleName("securityGroupRule"),
    user: getModuleName("users", 2),
    "key-pair": getModuleName("keyPair"),
    "backup-center": getModuleName("backupCenter"),
    "static-route": getModuleName("staticRoute"),
    port: getModuleName("port", 2),
    project: "Project",
  };
};
export const ScrollToTop = () => {
  if (!window) {
    return;
  }
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

export const isKycVerified = (
  kycStatus: UserKYCStatusType,
  accountType: string,
): boolean => {
  const {
    user_type,
    isAadharVerified,
    isEmailVerified,
    isGSTVerified,
    gst_status,
    isIndia,
    sumSubVerificationStatus,
  } = kycStatus;

  if (
    isIndia &&
    user_type !== "individual" &&
    (isGSTVerified || gst_status === 3)
  ) {
    // For existing companies in India, if GST is verified then Aadhar verification is not required
    return true;
  }
  if (accountTypeWithoutKyc.includes(accountType.toLowerCase())) {
    return true;
  }

  if (!isEmailVerified) {
    return false;
  }

  if (isIndia) {
    //If in India and not company, check Aadhar verification
    if (!isAadharVerified) {
      return false;
    }

    if (user_type !== "individual" && (gst_status === 2 || gst_status === 0)) {
      return false;
    }
  } else {
    // 4. If not in India, check SumSub verification status
    return !!sumSubVerificationStatus;
  }

  // 5. All checks passed
  return true;
};
