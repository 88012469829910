// ** Type import
import { VerticalNavItemsType } from "src/@core/layouts/types";
import Compute from "src/assets/icons/compute";
import Dashboard from "src/assets/icons/dashboard";
import Kubernetes from "src/assets/icons/kubernetes";
import Message from "src/assets/icons/message";
import Networks from "src/assets/icons/networks";
import TotalTickets from "src/assets/icons/totalTickets";
import Volume from "src/assets/icons/volume";
import Zoho from "src/assets/icons/zoho";
import { getHorizonPortalURL, getRouteWithParams } from "src/routes";
import S3 from "src/assets/icons/s3-storage";
import Utilities from "src/assets/icons/utilites";
import { getPublicCloudUrl } from "src/utils/publicCloud";
import Billing from "src/assets/icons/billing";
import BillingIcon from "src/assets/icons/BillingIcon";
import { Router } from "next/router";
import { moduleNames } from "src/utils/moduleName";
import appConfig from "src/configs/app";
import apiConfig from "src/configs/api";
import { getModuleName } from "src/configs/helper";
import GetStarted from "src/assets/icons/getStarted";
import Logs from "src/assets/icons/logs";
import { IAMIcon } from "src/assets/icons/IAMIcon";

const navigation = (): VerticalNavItemsType => {
  return [
    {
      title: "Home",
      icon: Dashboard,
      action: "view",
      subject: "home",
      path: getPublicCloudUrl(),
    },
    {
      title: "Dashboard",
      icon: Dashboard,
      action: "view",
      subject: "dashboard",
      path: getRouteWithParams("dashboard"),
    },
    {
      title: "Get Started",
      icon: GetStarted,
      action: "view",
      subject: "get-started",
      path: getRouteWithParams("getStarted"),
    },
    {
      title: "Compute",
      icon: Compute,
      children: [
        {
          title: `${getModuleName("instance", 2)}`,
          action: "view",
          subject: "instances",
          path: getRouteWithParams("instances"),
        },
        {
          title: "Autoscaling",
          children: [
            {
              title: "Templates",
              icon: Zoho,
              action: "view",
              subject: "templates",
              path: getPublicCloudUrl("templateListing"),
            },
            {
              title: "Deployments",
              icon: Compute,
              action: "view",
              subject: "deployments",
              path: getPublicCloudUrl("deploymentListing"),
            },
          ],
        },
      ],
    },
    {
      title: "Storage",
      icon: Volume,
      children: [
        {
          title: "Images",
          action: "view",
          subject: "images",
          path: getPublicCloudUrl("images"),
        },
        {
          title: `${getModuleName("volume", 2)}`,
          action: "view",
          subject: "volumes",
          path: getRouteWithParams("volume"),
        },
        {
          title: `${getModuleName("backup", 2)}`,
          action: "view",
          subject: "backup",
          path: getRouteWithParams("backup"),
        },
        {
          title: `${getModuleName("snapshot", 2)}`,
          action: "view",
          subject: "snapshots",
          path: getRouteWithParams("snapshots"),
        },
      ],
    },
    {
      title: "Networks",
      icon: Networks,
      children: [
        {
          title: `${getModuleName("vpn")}`,
          action: "view",
          subject: "vpns",
          path: getRouteWithParams("vpn"),
        },
        {
          title: `${getModuleName("vpc", 1)}`,
          action: "view",
          subject: "vpc",
          path: getRouteWithParams("vpc"),
        },
        {
          title: `${getModuleName("routers")}`,
          action: "view",
          subject: "routers",
          path: getRouteWithParams("routers"),
        },
        {
          title: getModuleName("floatingIp"),
          action: "view",
          subject: "floating-ips",
          path: getRouteWithParams("floatingIP"),
        },
        {
          title: "Load Balancer",
          action: "view",
          subject: "load-balancers",
          path: getPublicCloudUrl("loadBalancerListing"),
        },
        {
          title: `${getModuleName("securityGroup")}`,
          action: "view",
          subject: "security-groups",
          path: getRouteWithParams("securityGroups"),
        },
      ],
    },
    {
      title: "S3 Storage",
      icon: S3,
      action: appConfig.showS3Storage ? "view" : "hide",
      subject: appConfig.showS3Storage ? "acl" : "hide",
      children: [
        {
          title: "Buckets",
          action: "view",
          subject: "s3-buckets",
          path: getRouteWithParams("s3Bucket"),
        },
        {
          title: "Users",
          action: "view",
          subject: "s3-users",
          path: getRouteWithParams("s3Users"),
        },
        {
          title:
            moduleNames["accesskey"] && moduleNames["accesskey"].version1
              ? moduleNames["accesskey"].version1
              : "Access Key",
          action: "view",
          subject: "s3-access-keys",
          path: getRouteWithParams("s3AccessKey"),
        },
      ],
    },
    {
      title: "Kubernetes",
      icon: Kubernetes,
      children: [
        {
          title: "Overview",
          action: "view",
          subject: "k8s-overview",
          path: getRouteWithParams("kubernetes"),
        },
        {
          title: "K8s Security Groups",
          action: "view",
          subject: "k8sSecurityGroups",
          path: getRouteWithParams("k8sSecurityGroups"),
        },
        {
          title: "K8s Load Balancer",
          action: "view",
          subject: "k8sLoadBalancer",
          path: getRouteWithParams("k8sLoadBalancer"),
        },
        {
          title: "K8s Volumes",
          children: [
            {
              title: "Volumes",
              action: "view",
              subject: "k8sVolumes",
              path: getRouteWithParams("k8sVolumes"),
            },
            {
              title: "Snapshots",
              action: "view",
              subject: "k8sSnapshots",
              path: getRouteWithParams("k8sSnapshot"),
            },
          ],
        },
      ],
    },
    {
      title: "Utilities",
      icon: Utilities,
      children: [
        {
          title: `${getModuleName("users")}`,
          action: "view",
          subject: "users",
          path: getRouteWithParams("users"),
        },
        {
          title: `${getModuleName("keyPair")}`,
          action: "view",
          subject: "key-pairs",
          path: getRouteWithParams("keyPairs"),
        },
        {
          title: "Horizon Portal",
          action: "view",
          subject: "horizon-portal",
          path: getHorizonPortalURL(),
        },
        {
          title: `${getModuleName("backupCenter")}`,
          children: [
            {
              title: "Dashboard",
              action: "view",
              subject: "backup-centers-dashboard",
              path: getRouteWithParams("backupCenterDashboard"),
            },
            {
              title: `${getModuleName("backupCenterPolicy", 2)}`,
              action: "view",
              subject: "backup-centers-policies",
              path: getRouteWithParams("backupCenterPolicies"),
            },
            {
              title: `${getModuleName("backupCenterConfiguredBackup", 2)}`,
              action: "view",
              subject: "backup-centers-configure",
              path: getRouteWithParams("backupCenterConfigureBackup"),
            },
            {
              title: `${getModuleName("backupCenterTask", 2)}`,
              action: "view",
              subject: "backup-centers-tasks",
              path: getRouteWithParams("backupCenterTasks"),
            },
            {
              title: `${getModuleName("backupCenterNotification", 2)}`,
              action: "view",
              subject: "backup-centers-notifications",
              path: getRouteWithParams("backupCenterNotifications"),
            },
          ],
        },
      ],
    },
    {
      title: "IAM",
      icon: IAMIcon,
      children: [
        {
          title: `${getModuleName("userManagement")}`,
          action: "view",
          subject: "iam-user",
          path: getRouteWithParams("userManagement"),
        },
        {
          title: `${getModuleName("groupManagement")}`,
          action: "view",
          subject: "iam-group",
          path: getRouteWithParams("groupManagement"),
        },
        {
          title: `${getModuleName("policyManagement")}`,
          action: "view",
          subject: "iam-policy",
          path: getRouteWithParams("policyManagement"),
        }
      ],
    },
    {
      title: "Tickets",
      icon: Zoho,
      children: [
        {
          title: "Dashboard",
          action: "view",
          subject: "zoho-dashboard",
          path: getRouteWithParams("zohoDashboard"),
        },
        {
          title: "Tickets ",
          action: "view",
          subject: "zoho-tickets",
          path: getRouteWithParams("zohoTickets"),
        },
      ],
    },

    {
      title: "Billing",
      icon: BillingIcon,
      action: appConfig.showBillingConsumption ? "view" : "hide",
      subject: appConfig.showBillingConsumption ? "acl" : "hide",
      children: [
        {
          title: "Overview",
          action: "view",
          subject: "billing-overview",
          path: getRouteWithParams("billingOverview"),
        },
        {
          title: "Summary",
          action: "view",
          subject: "billing-summary",
          path: getRouteWithParams("billingSummary"),
        },
      ],
    },
    {
      title: "Logs",
      icon: Logs,
      action: "view",
      subject: "logs",
      children: [
        {
          title: getModuleName("activityLogs", 2),
          action: "view",
          subject: "activity-logs",
          path: getRouteWithParams("activityLogs"),
        },
      ],
    },
  ];
};
export default navigation;

// export const sideBarMenuItems = [
//   { text: "Dashboard", icon: <Dashboard />, url: "/" },
//   { text: "Compute", icon: <Compute />, url: "/" },
//   { text: "Volume", icon: <Volume />, url: "/" },
//   { text: "CDN Distributions", icon: <CDN />, url: "/" },
//   { text: "Networks", icon: <Networks />, url: "/" },
//   { text: "Storage", icon: <S3 />, url: "/" },
//   { text: "Kubernetes", icon: <Kubernetes />, url: "/" },
//   { text: "Backup Center", icon: <Backup />, url: "/" },
//   { text: "Utilities", icon: <Utilities />, url: "/" },
//   { text: "Zoho", icon: <Zoho />, url: "/" },
// ];

// export const nestedItems: NestedItems = {
//   Compute: [
//     { text: "Instances", icon: "", url: "/" },
//     { text: "Autoscaling", icon: "", url: "/" },
//   ],
//   Storage: [
//     { text: "Images", icon: "", url: "/" },
//     { text: "Snapshots", icon: "", url: "/" },
//   ],
//   Networks: [
//     { text: "VPN", icon: "", url: "/" },
//     { text: "Network", icon: "", url: "/" },
//     { text: "Routers", icon: "", url: "/" },
//     { text: "Floating IP", icon: "", url: "/" },
//     { text: "Load Balancer", icon: "", url: "/" },
//     { text: "Security Group", icon: "", url: "/" },
//   ],
//   Utilities: [
//     { text: "Users", icon: "", url: "/" },
//     { text: "Key Pair", icon: "", url: "/" },
//     { text: "Horizon Portal", icon: "", url: "/" },
//   ],
//   Billing: [
//     { text: "Add Funds", icon: "", url: "/" },
//     { text: "My Quotes", icon: "", url: "/" },
//     { text: "My Invoices", icon: "", url: "/" },
//     { text: "Credit History", icon: "", url: "/" },
//     { text: "Payment Methods", icon: "", url: "/" },
//   ],
// };

// export const sideBarItems = [
//   { text: "Services", icon: <Dashboard />, url: "/" },
//   { text: "Billing", icon: <Compute />, url: "/" },
//   { text: "Support", icon: <Volume />, url: "/" },
// ];
